import "./Features.css";
import sections from "../../constants/data";
import SingleFeatures from "./SingleFeatures";

const Features = () => {
  return (
    <section className="features section-p bg-md-black" id="features">
      <div className="container ">
        <h1 className="item-title text-white text-center centered-intro-2">
          <span className="   contaier-f large-heading-2">
            Frequently asked <span className="color-gradient">questions</span>
          </span>
          <p className="fs-15 text">
            Here's the answer to the most common asked questions.
          </p>
        </h1>

        <div className="features-content">
          <div className="item-list text-white">
            {sections.features.map((feature) => {
              return <SingleFeatures key={feature.id} {...feature} />;
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
