import "./Services.css";

const SingleService = ({ service }) => {
  const boxContent = [];
  if (service.box) {
    service.box.forEach((boxItem) => {
      boxContent.push(<p className="box-item">{boxItem.text}</p>);
    });
  }
  return (
    <div className="item bg-dark translate-effect gradient-border-div ">
      <h4 className="item-title-ser fs-25">{service.title}</h4>
      <p className="fs-19 text-ser">{service.text}</p>

      <div className="mt">{boxContent}</div>
    </div>
  );
};

export default SingleService;
