import Navbar from "../Navbar/Navbar";
import { MdEmail } from "react-icons/md";

import "./Header.css";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import images from "../../assets/images/icon-g-log-w.png";

const Header = () => {
  return (
    <header className="header flex flex-center flex-column">
      <Navbar />
      <div className="container-header">
        <div className="header-content flex flex-column ">
          <div className="section-t-header">
            <h1 className="text-uppercase header-title ">
              {/* Hello,<br></br> We are{" "} */}
              Dreamy, breezy.<br></br>
              <span className="header-title-span">pixntel</span> make Your
              Vision Easy.
            </h1>
            <p className="text-lead">
              Beautiful digital experiences that develop brand and drive
              business growth.
            </p>
            <div className="header-btns">
              <a href="mailto:contact@pixntel.com">
                <button
                  type="button"
                  className="btns"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <MdEmail style={{ fill: "white" }} />
                  <span>contact@pixntel.com</span>
                </button>
              </a>
            </div>
          </div>

          <div className="line-separator"></div>
        </div>
      </div>

      <div>
        <FloatingWhatsApp
          phoneNumber="+201150943836" // Replace with your actual phone number
          accountName="Customer Service Team" // Optional
          chatMessage="Hi there 👋 . How can we help with pixntel ?" // Optional pre-filled message
          messageDelay={2} // Optional delay (seconds) before chat message appears
          avatar={images} // Optional avatar image URL
          darkMode={true} // Optional dark mode
          allowClickAway={false}
          allowEsc={true} // Optional
          // @ts-ignore
          statusMessage={"We typically reply in a few minutes          "}
          notification={false}
          notificationLoop={3}
          // style={{ border: "1px solid rgb(7 94 84)" }}
        />
      </div>
    </header>
  );
};

export default Header;
