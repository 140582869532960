import {
  FaPaperPlane,
  FaRocket,
  FaPhoneAlt,
  FaEnvelopeOpen,
} from "react-icons/fa";

import { BiDollarCircle } from "react-icons/bi";

const gradient = "url(#blue-gradient)";

const services = [
  {
    id: 2,
    icon: <BiDollarCircle style={{ fill: gradient }} />,
    title: "Brand Identity    ",
    text: "New companies as well as established Brand, Marketing and Creative teams rely on pixntel to create or improve their logo, visual identity, style guide, templates and more based on your marketing strategy, customer feedback, competitive intelligence and more.",
    box: [
      { text: "Graphic Design" },
      { text: "Logotype" },
      { text: "Company Graphics" },
      { text: "Packaging" },
    ],
  },
  {
    id: 1,
    icon: <FaPaperPlane style={{ fill: gradient }} />,
    title: "Digital Design",
    text: "World-class websites using modern design practices. We will design and build a custom digital design responsive  geared to achieve your specific goals.We offers high-end web design expertise, tailored to meet your unique business needs.",
    box: [
      { text: "UI/UX" },
      { text: "Web App" },
      { text: "Mobile App" },
      { text: "SaaS" },
      { text: "Web Design" },
    ],
  },
  {
    id: 3,
    icon: <FaRocket style={{ fill: gradient }} />,
    title: "E-Commerce",
    text: "With the growth of online shopping, e-commerce websites are in high demand. Businesses selling products or services can create a website to showcase their offerings and allow customers to purchase online.",
    box: [
      { text: "Landing Page" },
      { text: "Online-Store" },
      { text: "Retail Apps" },
      { text: "Marketing Design" },
    ],
  },
  {
    id: 4,
    title: "Video & Animation",
    text: "We have highly skilled video editors, artists, animators, and motion designers working in unison to create masterpieces! Whatever your business needs, we can get just the right video content delivered to you at the right time. ",
    box: [
      { text: "Editing & Post-Production" },
      { text: "animation" },
      { text: "Social & Marketing Videos" },
    ],
  },
];

const features = [
  {
    id: 10,
    title: "How do you manage projects? ",
    text: " we are flexible with how we manage projects. Adopting Teamwork as our project management tool, you’ll have full oversight of the tasks that are completed, in progress, and coming up. You’re able to liaise directly with regularly over email or whatsapp.",
  },
  {
    id: 11,
    title: "What is the turnaround time?",
    text: "Our creative professionals  will work on your requests every business day, all year long.In 90% of the case, we deliver the first update after 24h.",
  },
  {
    id: 12,
    title: "Do I own the rights to the designs?",
    text: "Yes. All of our work is done for you and for you only. You have complete ownership of the files as soon as you received them and are free to use them as you please. Source files (PSD, AI, INDD, FIG, SKETCH) are always included along with PNG, JPEG, SVG & PDF",
  },
  {
    id: 13,
    title: "Do you collaborate and integrate with other teams?    ",
    text: "At pixntel, we believe that having strong working relationships with internal teams and external partners is key to delivering the best results. Many of our clients have existing in-house  teams which we collaborate with to share our knowledge and experience.",
  },
  {
    id: 14,
    title: "What are the payment methods?",
    text: "wastern union, PayPal, and a few other local payment options that may be available in your location.",
  },
  {
    id: 15,
    title: "What kind of Project can I request?",
    texts: [
      { text: "1) Video Editing  " },
      { text: "2) Real Estate Videos " },
      { text: "3) Podcasts Videos" },
      { text: "4) Presentation Videos  " },
      { text: "5) Social Media Videos" },
      { text: "6) Social Media Short Videos" },
      { text: "7) Website Videos " },
      { text: "8) Dropshipping Video Ads " },
      { text: "9) App & Website Promo Videos " },
      { text: "10) Intro & Outro Videos " },
      { text: "11) thumbnail videos " },
      { text: "12) Logo Animation" },
      { text: "13) Text Animation " },

      { text: "14) UI/UX design " },
      { text: "15) Mobile App " },
      { text: "16) Web App  " },
      { text: "17) WordPress Web " },
      { text: "18) SaaS design " },
      { text: "19) Web Design  " },

      { text: "20) Logo design" },
      { text: "21) Graphic Design " },
      { text: "22) Packaging " },
      { text: "23) Advertising banners design " },
      { text: "24) Infographics design " },
      { text: "25) Brochure design " },
      { text: "26) Flyers, Booklets & Posters design " },
      { text: "27) Business Cards design  " },
      { text: "28) Social Media Covers design" },

      { text: "29) Landing Page    " },
      { text: "30) Online-Store    " },
      { text: "31) Retail Apps    " },
      { text: "32) Marketing Design    " },

      { text: "33) & more " },
    ],
  },
];

const contact = [
  {
    id: 25,
    icon: <FaPhoneAlt style={{ fill: gradient }} />,
    info: "+201150943836",
    text: "Lorem ipsum dolor sit mattis amet consectetur adipiscing",
  },
  {
    id: 26,
    icon: <FaEnvelopeOpen style={{ fill: gradient }} />,
    info: "contact@pixntel.com",
    // info: "hello@pixntel.com",
    // info: "pixntel-team@pixntel.com",
    text: "Lorem ipsum dolor sit mattis amet consectetur adipiscing",
  },
];

const sections = {
  services,
  features,
  contact,
};

export default sections;
