import "./Footer.css";

const Footer = () => {
  // let date = new Date();

  return (
    <footer className="footer bg-black flex flex-center" id="footer">
      <div className="container flex flex-center w-100">
        <div className="grid footer-content text-center">
          <p className="text">© 2024 pixntel.com. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
