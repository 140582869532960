import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "../../assets/images/logo4-final.png";

const Navbar = () => {
  return (
    <nav className="navbar w-100 flex">
      <div className="container w-100">
        <div className="navbar-content flex fw-7">
          <div className="brand-and-toggler flex flex-center w-100 ">
            <Link
              to="/"
              className="navbar-brand flex fs-26"
              style={{ gap: "6px" }}
            >
              <img src={logo} />
              pixntel
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
