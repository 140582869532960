import "./Contact.css";
import Info from "./Info";

const Contact = () => {
  return (
    <section className="contact section-p-top bg-md-black" id="contact">
      <h1 className="text-white text-center pd-95">
        <span className="fs-26 color-gradient large-heading-3">CONTACT US</span>
        {/* <p className="fs-15 text"> What we can do</p> */}
      </h1>

      <Info />
    </section>
  );
};

export default Contact;
