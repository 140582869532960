import "./Qualities.css";

const Qualities = () => {
  return (
    <section className="qualities section-p bg-md-black item-q" id="qualities">
      <div className="container ">
        <div className="qualities-content grid">
          <div className="content-left">
            <div className="section-t text-center">
              <p className="fs-19 color-gradient">Who is it for?...</p>
              <h3 className="large-heading-2">What is agency pixntel</h3>
              <p className="textw">
                We are a design and technology agency that builds world class
                products for the digital age. pixntel is the agency of choice
                for results-driven managers and companies.
              </p>
            </div>
          </div>
        </div>

        <div className="flex-q-r border-shadow bg-b ">
          <div className="who-is-tabs-1 item border-rad-tl" id="who-is-tabs">
            <div className="who-is-tabs-nav ">
              <div className="who-is-btn">
                <span>For Small Businesses</span>
              </div>
            </div>
            <div className="who-is-tabs-main">
              <div className="who-is-item who-is-item-bord-r">
                <div className="who-tab-inf">
                  <h3 className="who-tab-title">
                    Flat-rate Creative Services to help you grow your business.{" "}
                  </h3>
                  <div className="who-tab-desc">
                    Don't break your budget or settle for sloppy creative work
                    that harms your business. Knock your competitors out of the
                    park while saving money.
                  </div>
                  <ul className="who-tab-list">
                    <li>Scale your business</li>
                    <li>Cut project costs up to 70%</li>
                    <li>Limit risk with no contracts</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="who-is-tabs item border-rad-tr" id="who-is-tabs">
            <div className="who-is-tabs-nav ">
              <div className="who-is-btn">
                <span>For Agencies</span>
              </div>
            </div>
            <div className="who-is-item">
              <div className="who-tab-inf">
                <h3 className="who-tab-title">
                  Multiply your agency output without multiplying your overhead
                  costs.{" "}
                </h3>
                <div className="who-tab-desc">
                  Overwhelmed? Overworked? Not anymore! Our vetted Creative
                  Services can take care of your own or your clients' requests,
                  allowing you to focus on what matters.
                </div>
                <ul className="who-tab-list">
                  <li>Save time and money on creative production</li>
                  <li>Scale up and down as needed</li>
                  <li>Hit unrealistic client deadlines</li>
                </ul>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Qualities;
