import "./Services.css";
import sections from "../../constants/data";
import SingleService from "./SingleService";

const Services = () => {
  return (
    <section className="services section-p-service bg-md-black" id="services">
      <div className="container">
        <h1 className="item-title text-white text-center">
          <span className="fs-26 color-gradient large-heading-3">
            OUR SERVICES
          </span>
          <p className="fs-15 text"> What we can do</p>
        </h1>

        <div className="services-content  ">
          <svg width="1em" height="1em">
            <linearGradient
              id="blue-gradient"
              x1="100%"
              y1="100%"
              x2="0%"
              y2="0%"
            >
              <stop stopColor="#55b3d5" offset="0%" />
              <stop stopColor="#5764de" offset="100%" />
            </linearGradient>
          </svg>

          <div className="item-list  text-white text-center ">
            {sections.services.map((service) => {
              return <SingleService service={service} key={service.id} />;
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
