import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import firebase from "firebase/app";
// import "firebase/analytics";
// import { firebaseConfig } from "./firebaseConfig";

const root = ReactDOM.createRoot(document.getElementById("root"));

// firebase.initializeApp(firebaseConfig);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}></Route>
    </Routes>
  </BrowserRouter>
);
