import "./App.css";
import Header from "./components/Header/Header";
import Services from "./components/Services/Services";
import Qualities from "./components/Qualities/Qualities";
import Features from "./components/Features/Features";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import ProvenEffeciency from "./components/ProvenEffeciency/ProvenEffeciency";

// import { initializeApp } from "firebase/app";
// import { getAnalytics, logEvent, setCurrentScreen } from "firebase/analytics";
import "firebase/analytics"; // Include for compatibility in older versions
// import { useEffect } from "react";

// const firebaseConfig = {
//   apiKey: "AIzaSyBNUZzN16_sBLY5rQ3lIF0I036uaNpftk4",
//   authDomain: "pixntel.firebaseapp.com",
//   projectId: "pixntel",
//   storageBucket: "pixntel.appspot.com",
//   messagingSenderId: "239599798527",
//   appId: "1:239599798527:web:9f79144a930fbb0b70e4ca",
//   measurementId: "G-DBRDXFSCXW",
// };

function App() {
  // useEffect(() => {
  //   const handlePageView = () => {
  //     const currentScreen = window.location.pathname; // Replace with your logic

  //     // Choose based on your Firebase version:
  //     if (typeof setCurrentScreen === "function") {
  //       setCurrentScreen(
  //         getAnalytics(initializeApp(firebaseConfig)),
  //         currentScreen
  //       );
  //     } // Use setCurrentScreen if available

  //     logEvent(getAnalytics(initializeApp(firebaseConfig)), "page_view");
  //   };

  //   handlePageView();
  //   window.addEventListener("popstate", handlePageView);

  //   return () => {
  //     window.removeEventListener("popstate", handlePageView);
  //   };
  // }, []);

  return (
    <div className="App">
      <div className="App-content">
        <Header />
        <ProvenEffeciency />
        <Services />
        <Qualities />
        <Contact />
        <Features />
      </div>
      <Footer />
    </div>
  );
}

export default App;
