import { useState } from "react";
import "../ProvenEffeciency/ProvenEffeciency.css";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const ProvenEffeciency = () => {
  const [counterOn, setCounterOn] = useState(false);

  return (
    <section className="services section-p bg-md-black" id="services">
      <div className="container">
        <h1 className="item-title text-white text-center">
          <span className=" color-gradient large-heading-3">
            Proven Effiency
          </span>
          <p className="text">
            {" "}
            No job postings, No interviews, No inefficient freelancers, No
            headache.
          </p>
        </h1>

        <div className="wrapper">
          <ScrollTrigger
            onEnter={() => setCounterOn(true)}
            onExit={() => setCounterOn(false)}
          >
            <div className="numbers flex text-white  ">
              <div className="flnos">
                <div className="numbers-value text-center">
                  <div className="n-value">
                    {counterOn && <CountUp start={0} end={650} duration={3} />}+
                  </div>
                </div>
                <div className="numbers-title">
                  <div>
                    <div className="n-tittle">tasks done </div>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="numbers-value text-center">
                  <div className="n-value">
                    {counterOn && <CountUp start={0} end={35} duration={3} />}+
                  </div>
                </div>
                <div className="numbers-title">
                  <div>
                    <div className="n-tittle">
                      full-time Creative Professionals{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="numbers-value text-center">
                  <div className="n-value">
                    {counterOn && <CountUp start={0} end={99} duration={3} />}%
                    {/* <CountUp start={0} end={100} duration={2} />% */}
                  </div>
                </div>
                <div className="numbers-title">
                  <div>
                    <div className="n-tittle">
                      client satisfaction on projects work with our agency{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ScrollTrigger>
        </div>
      </div>
    </section>
  );
};

export default ProvenEffeciency;
